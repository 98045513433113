<template>
  <div class="content">
    <div class="export-loading" v-if="loading">
      <LoadingAnim />
    </div>
    <PageHeader :breadcrumbLinks="breadcrumbLinks" title="Whatsapp" :subtitle="'Deny List ' + $t('generic-str.and') + ' OPT-Outs'" />
    <section class="page-content container-fluid">
      <div class="row">
        <!-- Deny List -->
        <div class="denylist-col col-6 col-xl-6">
          <div class="card">
            <div class="card-header header_options">
              <div class="card-title header_title">Deny List</div>
              <form @submit.prevent="search" class="form-group col-6 m-auto mb-0">
                <div class="input-group">
                  <input
                    placeholder=""
                    type="text"
                    class="form-control"
                    :v-model="searchQuery"
                    ref="searchInput"
                  />
                  <div class="input-group-prepend">
                    <button @click="search" type="button" class="btn icon_btn m-0">
                      <span class="material-symbols-outlined"> search </span>
                    </button>
                  </div>
                </div>
              </form>
              <div class="opts_card">
                <!-- Criar novo -->
                <button class="btn" v-modal="{ target: 'create-blacklist-modal' }">
                  <span class="material-icons add_person"> add </span>
                </button>
                <!-- Exportar -->
                <button-element
                  type=""
                  @click="download"
                  :disabled="blacklists.length == 0">
                  <span class="material-icons-outlined title">download</span>
                </button-element>
                <!-- Excluir -->
                <button class="btn"
                  :class="{'qt-loader qt-loader-mini qt-loader-right': isSending,}"
                  @click="deleteAll"
                  v-bind:disabled="selectedBlacklists.length == 0 || isSending">
                  <span class="material-symbols-outlined title">delete</span>
                </button>
              </div>
            </div>
            <div v-if="!blacklists.length && fetched" class="card-body">
              <LottieAnimNoData />
              <h5 class="card-title text-center">
                {{ $t('warnings.blacklist.none-deny') }}
              </h5>
              <!-- Nenhum deny list cadastrado -->
              <p class="text-center">{{ $t('warnings.blacklist.register-deny') }}</p>
              <!-- Cadastre seu primeiro deny list. -->
            </div>
            <div class="card-body" v-if="fetched">
              <div class="table-responsive" v-if="blacklists.length">
                <table class="table table-infos">
                  <thead>
                    <tr>
                      <!-- <th scope="col">
                        {{ $tc('app.name', 1) }}
                      </th> -->
                      <th class="checkbox-wrapper" scope="col">
                        <div class="control-group opt-2">
                          <label class="control control-checkbox" for="customCheckTAll"
                             ><input id="customCheckTAll" v-model="selectAllBlacklists" type="checkbox" />
                            <div class="control_indicator"></div>
                          </label>
                        </div>
                        {{ $tc('generic-str.lbl-number', 1) }}
                      </th>
                      <th scope="col">
                        {{ $t('generic-str.expire') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(blacklist, i) in blacklists"
                      :key="blacklist.id">
                      <!-- <td>
                        <span class="img-wrapper">
                          <div class="image">R</div>
                          <span>Rodrigo Silva</span>
                        </span>
                      </td> -->
                      <td data-label="Número: ">
                        <span class="checkbox-wrapper">
                          <div class="control-group opt-2">
                            <label class="control control-checkbox" :for="'blacklist-check' + i">
                              <input
                              v-model="selectedBlacklists"
                              :id="'blacklist-check' + i"
                              :value="blacklist" type="checkbox" />
                              <div class="control_indicator"></div
                            ></label>
                          </div>
                          <span>+{{ blacklist.mobile_number }}</span>
                        </span>
                      </td>
                      <td v-if="blacklist.expired_at"
                        data-label="Expira em: ">
                        <span>
                          <span>{{ blacklist.expired_at }}</span>
                        </span>
                      </td>
                      <td
                        v-else
                        data-label="Expira em: "
                      >
                        {{ $t('generic-str.never') }}
                        <!-- Nunca -->
                      </td>
                      <td>
                        <dropdown>
                          <template v-slot:text>
                            <span class="material-symbols-outlined title">
                              more_vert
                            </span>
                          </template>
                          <template #items>
                            <!-- Editar -->
                            <button
                              v-modal="{
                                target: 'blacklist-modal',
                                data: blacklist,
                              }"
                              class="dropdown-item"
                            >
                              {{ $t('generic-str.edit') }}
                            </button>
                            <!-- Remover -->
                            <button
                              class="dropdown-item"
                              @click="remove(blacklist.id, blacklist.mobile_number)"
                            >
                              {{ $t('generic-str.remove') }}
                            </button>
                          </template>
                        </dropdown>
                        <!-- <div class="dropdown">
                          <a
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            ><span class="material-symbols-outlined title">
                              more_vert
                            </span></a
                          >
                          <div class="dropdown-menu" style="">
                            <a class="dropdown-item"> Edit </a
                            ><button class="dropdown-item">Remove</button>
                          </div>
                        </div> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-else class="qt-block-ui relative" style="padding: 120px" />
            <div class="card-footer">
              <div class="container-fluid">
                <div class="row">
                  <pagination :lastPage="pages" @change="fetch" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--/Deny List -->
        <!-- OPT Outs -->
        <div class="col-lg-6">
          <opted-card />
        </div>
        <!--/OPT Outs -->
      </div>
      <!-- <div class="row"> -->
        <!-- Keyword -->
        <!-- <div class="col-lg-4"> -->
          <!-- <keywords-card ref="keywords" /> -->
        <!-- </div> -->
        <!--/Keyword -->
      <!-- </div> -->
    </section>
    <create-blacklist-modal id="create-blacklist-modal" @submit="fetch()" />
    <blacklist-modal id="blacklist-modal" @submit="fetch()" />
    <create-optouts-modal id="create-optouts-modal" @submit="fetchAll(1)" />
    <optouts-modal id="optouts-modal" @submit="fetchAll(1)" />
    <create-key-modal id="create-key-modal" @submit="fetchKeywords" />
    <key-modal id="key-modal" @submit="fetchKeywords" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import SmsService from '@/services/sms.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/rebranding/Pagination.vue';
import ButtonElement from '@/components/rebranding/elements/ButtonElement.vue';
import Dropdown from '@/components/Dropdown.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
// Blacklist
import CreateBlacklistModal from '@/components/rebranding/whatsapp/CreateBlacklistModal.vue';
import BlacklistModal from '@/components/rebranding/whatsapp/BlacklistModal.vue';
import BlacklistService from '@/services/blacklist.service';
// Optout
import OptedCard from '@/components/rebranding/whatsapp/optouts/OptedCard.vue';
/* import KeywordsCard from '@/components/rebranding/whatsapp/optouts/KeywordsCard.vue'; */
import CreateKeyModal from '@/components/rebranding/whatsapp/CreateKeyModal.vue';
import KeyModal from '@/components/sms/KeyModal.vue';
import CreateOptoutsModal from '@/components/sms/CreateOptoutsModal.vue';
import OptoutsModal from '@/components/sms/OptoutsModal.vue';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    CreateBlacklistModal,
    BlacklistModal,
    Pagination,
    ButtonElement,
    Dropdown,
    LottieAnimNoData,
    LoadingAnim,
    OptedCard,
    /* KeywordsCard, */
    CreateKeyModal,
    KeyModal,
    CreateOptoutsModal,
    OptoutsModal,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/whatsapp/reports/history',
        2: '/whatsapp/denylist',
      },
      fetched: false,
      isSending: false,
      searchQuery: null,
      blacklists: [],
      form: {
        page: 1,
        channel: 'whatsapp',
      },
      pages: 1,
      selectedBlacklists: [],
    };
  },
  computed: {
    selectAllBlacklists: {
      get() {
        if (this.templates) {
          return this.selectedBlacklists.length === this.templates.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.blacklists.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedBlacklists = selected;
      },
    },
  },
  created() {
    this.fetch();
  },
  methods: {
    search() {
      this.fetched = false;
      const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      SmsService.getBlacklists(this.form).then(
        (response) => {
          this.fetched = true;
          this.blacklists = response.data;
          // console.log('Templates: ', this.blacklists);
          result = this.blacklists.filter((item) => word.toLowerCase().split(' ').every((v) => item.mobile_number.toLowerCase().includes(v)));
          // console.log('Return result: ', result);
          this.blacklists = result;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    fetch(page = 1) {
      this.form.page = page;
      this.fetched = false;
      SmsService.getBlacklists(this.form).then(
        (response) => {
          this.fetched = true;
          this.blacklists = response.data;
          this.pages = response.last_page;
          console.log('Objetos: ', response.data);
        },
        (error) => {
          this.content = error;
        },
      );
    },
    selectedBlacklistsChange(param) {
      this.selectedBlacklists = param.selectedBlacklists;
    },
    remove(id, number) {
      Swal.fire({
        title: 'Remover deny list',
        text: `Tem certeza que gostaria de remover o número ${number}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          toDelete.push(id);
          this.isSending = true;
          SmsService.deleteBlacklists({ ids: toDelete }).then(() => {
            this.fetch();
            this.$toast.show({
              title: 'Sucesso',
              content: 'Deny list removido',
              type: 'success',
            });
            this.$root.$emit('updated.blacklist');
            this.isSending = false;
          });
        }
      });
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('warnings.blacklist.remove-deny'),
        text: this.$t('warnings.blacklist.text-deny'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedBlacklists.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          SmsService.deleteBlacklists({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('warnings.blacklist.removed-deny'),
                type: 'success',
              });
              this.$root.$emit('updated.blacklist');
              this.isSending = false;
              this.selectedBlacklists = [];
            },
            (error) => {
              console.log(error);
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
    download() {
      const link = document.createElement('a');

      if (link.download === undefined) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: 'Sem suporte para download',
          type: 'danger',
        });

        return;
      }

      this.loading = true;

      BlacklistService.export({ channel: 'rcs' })
        .then(
          (data) => {
            const blob = new Blob([data], { type: 'text/csv;charset=utf-8;' });
            if (navigator.msSaveBlob) {
              // IE 10+
              navigator.msSaveBlob(blob, 'denylist.csv');
            } else {
              // feature detection
              // Browsers that support HTML5 download attribute
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', 'denylist.csv');
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          },
          (error) => {
            this.content = error;
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.loading = false;
        });
    },
    /* Optouts */
    fetchKeywords() {
      this.$refs.keywords.fetch(1);
    },
    selectedTemplatesChange(param) {
      this.selectedTemplates = param.selectedTemplates;
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  position: relative;
}
.export-loading {
  position: absolute;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, .2);
}

.btn i {
  line-height: 1;
  color: inherit;
}

/* Rebranding */
.eye_icon {
  color: #bfc5cb;
}
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
.darkmode .rebranding .form-control,
.rebranding .form-control {
  border-right: unset !important;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}

.header_options .input-group > input,
.header_options .input-group button {
  height: 50px;
}

.rebranding .add_person {
  cursor: pointer;
}

.rebranding .opts_card button {
  height: fit-content;
  padding: 0;
}

.rebranding .card .opts_card i {
  font-size: 22px;
  transition: 0.5s;
  padding: 5px;
}

.rebranding .card .opts_card i:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

.img-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.img-wrapper > .image {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #570069;
  width: 50px;
  height: 50px;
  padding: 24px;
  border-radius: 50%;
  color: #fff;
  font-weight: 400;
}

.table-infos td {
  vertical-align: unset;
}

.rebranding .card {
  height: calc(100% - 1.875rem);
}

.rebranding .purple-add {
  background-color: var(--clr-yup-purple);
  color: #fff !important;
  transition: 0.5s;
}

.rebranding .purple-add:hover {
  background-color: var(--clr-yup-purple-hover);
}

.list-options li {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
}

.list-options li .control-group {
  display: flex;
  gap: 10px;
  clear: both;
  width: 100%;
}

.list-options li .control-group .control {
  width: 100%;
}

.list-options li .control-group .control_indicator {
  float: left;
  margin-right: 10px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  gap: .7rem;
}

.table.table-infos th, .table.table-infos thead th {
  border-bottom: unset;
}

/* Static pagination */
.pagination {
  margin: 2px 0;
  white-space: nowrap;
  justify-content: flex-end;
}
/* @media (min-width: 1440px) {
  .denylist-col.col-lg-8 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
} */
@media (min-width: 992px) and (max-width: 1440px){
  .denylist-col.col-xl-8 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.denylist-col .card-body {
  min-height: 300px;
}

.denylist-col .table-responsive {
  height: 100%;
}
</style>
